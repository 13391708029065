<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75"
                    rounded="sm">
                    <b-row class="m-0">
                        <b-col xl="4">
                            <b-form-group label="Nº Equipo" label-for="equipment_id">
                                <b-form-input type="text" v-model="intervention.equipment_id" disabled />
                            </b-form-group>
                        </b-col>

                        <b-col xl="4">
                            <b-form-group label="Urgencia" label-for="account-roles">
                                <v-select v-model="intervention.urgent" label="name" :options="urgenOptions"
                                    :reduce="(option) => option.id">
                                    <template #no-options="{}">
                                        No hay tipos de urgencia.
                                    </template>
                                </v-select>
                                <small v-if="validation.urgent" class="text-danger w-100">
                                    {{ validation.urgent }}
                                </small>
                            </b-form-group>
                        </b-col>
                        <b-col xl="4">
                            <b-form-group label="TAG" label-for="account-roles">
                                <v-select v-model="intervention.tag_id" label="code" :options="tags"
                                    :reduce="(option) => option.id">
                                    <template #no-options="{}">
                                        No hay TAGs.
                                    </template>
                                </v-select>
                                <small v-if="validation.tag_id" class="text-danger">
                                    {{ validation.tag_id }}
                                </small>
                            </b-form-group>
                        </b-col>
                        <b-col xl="12">
                            <b-form-group label="Observaciones" label-for="">
                                <b-form-textarea v-model="intervention.description" type="text" placeholder="Observaciones"
                                    autocomplete="off" />
                                <small v-if="validation.description" class="text-danger">
                                    {{ validation.description }}
                                </small>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Foto de intervención" label-for="intervention_photo" class="m-05">
                                <b-form-file v-model="intervention.intervention_photo_file"
                                    placeholder="Elige un documento o déjalo ir aquí..."
                                    drop-placeholder="Elige un documento o déjalo ir aquí..." browse-text="Seleccionar" />
                                <small v-if="validation.intervention_photo_file" class="text-danger">
                                    {{ validation.intervention_photo_file }}
                                </small>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="m-0" align-h="start">
                        <b-col xl="5">
                            <b-button variant="primary" class="mt-2 mr-1" @click="sendData">
                                Guardar Cambios
                            </b-button>
                            <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCol, BOverlay, BButton, BRow, BFormGroup, BFormInput, BFormTextarea, BFormFile } from 'bootstrap-vue'
import { workCenters } from "@/constants/workCenters"
import helpers from "@/resources/helpers/helpers"
import { baseUrl } from "@/constants/app"
import vSelect from "vue-select"
import { mapState } from "vuex"
import {createHelpers} from "vuex-map-fields"

const {mapFields} = createHelpers({
    getterType: 'intervention/getField',
    mutationType: 'intervention/updateField',
})

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BOverlay,
        BButton,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormFile,
        vSelect,
    },
    data() {
        return {
            urgenOptions: [
                {
                    id: true,
                    name: 'Urgente',
                },
                {
                    id: false,
                    name: 'No urgente',
                }
            ],
            showLoading: false,
            workCenter: [],
            baseUrl,
            workCenters,
            helpers,
        }
    },
    computed: {
        ...mapState('intervention', ['validation']),
        ...mapState('tag', ['tags']),
        ...mapFields({
            intervention: 'intervention',
        }),
    },
    created() {
        this.$store.commit('intervention/RESET_STATE')
        this.$store.commit('intervention/SET_INTERVENTION', {
            equipment_id: this.$route.params.id
        })
        this.getTagsClient()
        this.getEquipment()
    },
    beforeDestroy() {
        this.$store.commit('SET_PAGE_TITLE', null)
    },
    methods: {
        getTagsClient() {
            this.$store.dispatch('tag/listClient', this.$route.params.id)
                .catch(error => {
                    console.log(error)
                })
        },
        getEquipment() {
            this.showLoading = true

            this.$store.dispatch('equipment/getEquipment', {
                id: this.$route.params.id,
                relations: ['tags', 'activeTag']
            }).then(response => {
                if (response.active_tag)
                    this.intervention.tag_id = response.active_tag[0].id

                this.$store.commit('SET_PAGE_TITLE', ' Crear intervención de Equipo "'+ response.serial_number+'"')
                this.showLoading = false
            }).catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
        sendData() {
            this.showLoading = true

            this.$store.dispatch('intervention/create', this.formatData())
                .then(response => {
                    this.showLoading = false
                    helpers.showToast('Intervencion creada correctamente', 'CheckIcon', 'success')
                    this.$router.push({ name: 'list_interventions_from_equipment', params: { id: this.$route.params.id, stateOpened: true }})
                })
                .catch(error => {
                    this.showLoading = false

                    console.log(error)
                })
        },
        formatData() {
            var formData = new FormData()
            helpers.appendFormData(formData, this.intervention, 'intervention')
            return formData
        },
        cancel() {
            this.$router.push({ name: 'sheet_equipment', id: this.$route.params.id })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
